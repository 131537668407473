<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header breadcrumb />
      <main class="flex-1 relative focus:outline-none bg-white">
        <div class="my-6 flex flex-col flex-1">
          <div
            class="flex justify-start text-black mx-2 p-3 shadow-lg border-2 bg-white rounded-lg"
          >
            <img
              class="float-left h-20 md:h-32 lg:h-40 self-center"
              :src="getImgUrl(preceptory.url)"
            />
            <div class="flex-1 px-2">
              <p
                class="font-bold text-xs text-red-700 text-center underline md:text-lg lg:text-2xl py-1"
              >
                {{ preceptory.name }}
              </p>
              <p
                class="text-xxs md:text-base lg:text-lg font-semibold italic text-justify"
              >
                {{ preceptory.description }}
              </p>
            </div>
          </div>
          <div class="flex flex-1 justify-evenly items-center">
            <ul
              class="text-black w-full mx-6 my-4 flex flex-wrap justify-center content-center items-center"
            >
              <li
                v-for="item in routes"
                :key="item.path"
                v-bind:class="{
                  hidden: getPermission(item.permission, 'get') == 0,
                }"
                class="w-1/2 md:w-1/3 2xl:w-1/5 p-2"
              >
                <router-link
                  :to="{ path: item.path }"
                  append
                  class="text-black flex flex-col w-full rounded-xl bg-white border-2 border-red-500 shadow justify-self-center object-center hover:bg-gray-200 p-3"
                >
                  <img
                    :src="getImgUrl(item.url)"
                    class="w-auto h-20 md:h-24 lg:h-28 object-contain p-1"
                  />
                  <div class="p-2 place-content-center">
                    <p
                      class="text-sm lg:text-base 2xl:text-lg text-center font-bold italic"
                    >
                      {{ item.name }}
                      <span
                        class="text-red-700 text-base lg:text-lg 2xl:text-xl not-italic"
                        >&#8250;</span
                      >
                    </p>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div
            class="justify-start text-black mx-2 p-3 shadow-lg border-2 bg-white rounded-lg"
          >
            <div class="font-bold text-tiny lg:text-lg 2xl:text-xl pb-2">
              Recent activities
            </div>
            <div>
              <ul class="text-black w-full">
                <li
                  class="py-1"
                  v-for="item in recentFiles"
                  :key="item.path"
                  v-bind:class="{
                    hidden: getPermission(item.permission, 'get') == 0,
                  }"
                >
                  <button
                    @click="routeTo(item)"
                    class="text-black flex flex-col w-full rounded-xl bg-white border-2 border-gray-200 justify-self-center object-center hover:bg-gray-200 py-1 px-2 md:py-2 md:px-3 overflow-auto"
                  >
                    <div class="hidden md:block">
                      <p
                        class="text-sm lg:text-base text-left font-bold truncate"
                      >
                        {{ item.fileName }}
                        <span class="font-normal px-1">in</span>
                        {{ getFolder(item.name) }}
                      </p>
                      <p
                        class="text-sm lg:text-tiny text-left italic font-semibold truncate"
                      >
                        <span class="font-normal"> by </span>
                        {{ item.createdBy }} <span class="font-normal">-</span>
                        {{
                          item.createdTime
                            | moment("add", `${utcOffset} minutes`)
                            | moment("from")
                        }}
                      </p>
                    </div>
                    <div class="block md:hidden">
                      <p
                        class="text-sm lg:text-base text-left font-bold truncate"
                      >
                        {{ item.fileName }}
                      </p>
                      <p
                        class="text-sm lg:text-tiny text-left font-bold italic truncate"
                      >
                        {{ getFolder(item.name) }}
                      </p>
                      <p
                        class="text-sm lg:text-tiny text-left italic font-semibold truncate"
                      >
                        <span class="font-normal"> by </span>
                        {{ item.createdBy }} <span class="font-normal">-</span>
                        {{
                          item.createdTime
                            | moment("add", `${utcOffset} minutes`)
                            | moment("from")
                        }}
                      </p>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import  preceptory  from "../../assets/json/preceptory.json";

import { categoriesRoute, categoriesName } from "../../constants/";
import api from "@/api/apiServices";

export default {
  name: "IndividualPreceptory",
  components: {
    HomeFooter,
    HomeHeader,
  },
  data() {
    return {
      utcOffset: this.$moment().utcOffset(),
      permission: this.$store.getters["security/currentPermission"],
      recentFiles: [],
      routes: [
        {
          icon: ["fas", "images"],
          path: "photo",
          url: "gallery_icon.png",
          name: "Gallery",
          permission: "Galleries",
        },
        {
          icon: ["fas", "file-alt"],
          path: "library",
          url: "articles_icon.png",
          name: "Library",
          permission: "Articles",
        },
        {
          icon: ["fas", "chart-area"],
          path: "presentation",
          url: "presentation_icon.png",
          name: "Presentations",
          permission: "Presentations",
        },
        {
          icon: ["fas", "scroll"],
          path: "summon",
          url: "summons_icon.png",
          name: "Summons",
          permission: "Summons",
        },
        {
          icon: ["fas", "hand-holding-heart"],
          path: "charity",
          url: "charity_icon.png",
          name: "Charitable Events",
          permission: "Charities",
        },
      ],
      preceptory: preceptory.filter(x=>x.id == this.$route.params.id).pop(),
    };
  },
  async mounted() {
    this.$route.meta.breadcrumb = this.$route.meta(this.$route).breadcrumb;
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });
    this.recentFiles = await this.getRecentFiles(this.$route.params.id);
  },
  methods: {
    async getRecentFiles(id) {
      api.getRecentFiles([id]).then((res) => {
        if (res != undefined) {
          this.recentFiles = res.data.data;
        }
      });
    },
    alertPermission() {
      this.$fire({
        title: "Access Error",
        text: "User does not have permissions to access the page",
        type: "error",
      });
    },
    getImgUrl(logo) {
      return require("../../assets/" + logo);
    },
    getPermission(module, type) {
      for (let i = 0; i < this.permission.length; i++) {
        const element = this.permission[i];
        if (module == element.module) {
          return element[type];
        }
      }
    },
    getPath(path) {
      var temp = path.split("/")[1];
      return categoriesRoute[temp];
    },
    getFolder(path) {
      var temp = path.split("/").slice(1, -1);
      temp[0] = categoriesName[temp[0]];
      temp.splice(1, 1);
      var string = temp.join("/");
      return string;
    },
    getCurrentFolder(path) {
      var temp = path
        .split("/")
        .slice(1)
        .join("/");
      if (temp != "") {
        return temp + "/";
      } else return temp;
    },
    routeTo(item) {
      this.$router.push({
        name: this.getPath(item.name),
        params: {
          path: this.getCurrentFolder(this.getFolder(item.name)),
          name: item.fileName,
        },
      });
    },
  },
};
</script>

<style scoped>
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>
